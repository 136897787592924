<main class="grayGreenBackground">
  <div class="home_name_top">
    <h1>Douglas L. Watts</h1>
  </div>
  <div class="contents">
    <nav class="links">
      <a
        class="resume"
        target="new"
        rel="noopener noreferrer"
        href="../../assets/documents/douglaslwatts_resume.pdf"
        >Resume</a
      >
      <br /><br />
      <label for="udemy_certificates">Udemy Certificates</label>
      <div class="udemy_certificates">
        <a
          href="https://www.udemy.com/certificate/UC-cac3972f-fe22-41c6-87ad-016804d2daaf/"
          target="new"
          rel="noopener noreferrer"
          >Linux Shell Scripting: Project-Based</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-TZJRCE2D/"
          target="new"
          rel="noopener noreferrer"
          >Shell Scripting: Automate CLI Tasks</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-9MWMIJHF/"
          target="new"
          rel="noopener noreferrer"
          >Linux Administration Bootcamp</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-b420ae01-a647-4e78-bcdc-06a538870bbb/"
          target="new"
          rel="noopener noreferrer"
          >Short and Sweet: Next-Level Git</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-4887f41d-02cd-41ad-a77e-80a53c8f7afc/"
          target="new"
          rel="noopener noreferrer"
          >Short and Sweet: Advanced Git</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-4SRW9SIG/"
          target="new"
          rel="noopener noreferrer"
          >C Programming for Beginners</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-026e8e05-c008-4af0-a62d-289d2284d4ee/"
          target="new"
          rel="noopener noreferrer"
          >Ultimate Rust Crash Course</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-d5f32dfc-3496-4002-9417-b7781c852d1d/"
          target="new"
          rel="noopener noreferrer"
          >Operating Systems- Part 1</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-864327ea-8627-421d-8b9b-fd67fbf6a30c/"
          target="new"
          rel="noopener noreferrer"
          >Theory of Computation</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-e638381b-76d2-4664-acba-ee9254171ee3/"
          target="new"
          rel="noopener noreferrer"
          >The Ultimate MySQL Bootcamp</a
        >
        <a
          href="https://www.udemy.com/certificate/UC-a20fac87-2f0d-4c33-b8a4-84ebf505ba9b/"
          target="new"
          rel="noopener noreferrer"
          >The Modern Python 3 Bootcamp</a
        >
      </div>
      <br /><br />
      <label for="git">Git Profiles</label>
      <div class="git">
        <a href="https://gitlab.com/douglaslwatts" target="new" rel="noopener noreferrer"
          >gitlab/douglaslwatts</a
        >
        <a href="https://github.com/douglaslwatts" target="new" rel="noopener noreferrer"
          >github/douglaslwatts</a
        >
      </div>
    </nav>
    <section class="main_body">
      <div class="home_name">
        <h1>Douglas L. Watts</h1>
      </div>

      <div class="grayGreen">
        <h2>Education</h2>

        <ul class="education">
          <li>
            Bachelor of Science<br />Computer Science, Minor in Mathematics<br />Western Carolina
            University<br />GPA: 3.97
          </li>
          <li>Associates of Science<br />AB-Tech<br />GPA: 3.92</li>
        </ul>

        <h2>Achievements</h2>

        <ul>
          <li>
            <h3>Western Carolina University</h3>
            <ul class="achievements">
              <li class="achievement">Graduated Summa Cum Laude</li>
              <li class="achievement">Dean's Outstanding Scholar in Computer Science</li>
              <li class="achievement">
                Beginning, Intermediate, and Advanced Computer Science Awards
              </li>
            </ul>
          </li>
        </ul>

        <h2 class="underlined">Current Aspirations</h2>

        <p>
          As a software engineer, I have some goals in progress that comprise my overall goal of
          gaining greater expertise in full stack web and mobile application development. I am working to
          increase my knowledge of the Spring Framework, learn some other backend technologies,
          further my understanding of Angular, learn React, learn React Native to get a start on
          mobile application development, and continue working to boost my SQL skills. I am also
          in the process of learning enough AWS and related infrastructure as code technologies to be
          capable of managing the devops side of things reasonably well. That is quite a lot, and it
          will take time to get to the level of expertise I foresee myself achieving. However, I will
          be an increasingly valuable asset to my company as I progress in this larger goal, and I am
          very excited about all of it!
        </p>

        <h2 class="underlined">Goals</h2>

        <ul class="aspirations">
          <li>Increase my knowledge of the Spring Framework</li>
          <li>Learn more backend technologies</li>
          <li>Further my skills in Angular</li>
          <li>Boost my SQL skills</li>
          <li>Learn React and React Native</li>
          <li>Learn infrastructure as code technologies</li>
          <li>Earn AWS Cloud Practitioner foundational certification</li>
          <li>Earn AWS AI Practitioner foundational certification</li>
          <li>Earn AWS Solutions Architect associate certification</li>
          <li>Earn AWS Developer associate certification</li>
          <li>Earn AWS Machine Learning associate certification</li>
          <li>Build my Git portfolio</li>
          <li>Be promoted from Junior to Mid-Level Software Engineer</li>
          <li>Be promoted from Mid-Level to Senior Software Engineer</li>
        </ul>
      </div>
    </section>
  </div>
</main>
